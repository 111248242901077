import {
  cibApple,
  cilMoodVeryGood,
  cilApplications,
  cilMoon,
  cilSun,
  cilPlus,
  cilMinus,
  cilTrash,
  cilPen,
  cilX,
  cilBuilding,
  cilLinkAlt,
  cilMenu,
  cilCaretLeft,
  cilCaretRight,
  cilBolt,
  cilSave,
  cilBold,
  cilItalic,
  cilStrikethrough,
  cilParagraph,
  cilHeader,
  cilListNumbered,
  cilList,
  cilActionUndo,
  cilActionRedo,
  cilImage,
  cilInbox,
  cilCalendar,
  cilMediaPlay,
  cilHistory,
  cilLowVision,
  cilCloudUpload,
  cilCloudDownload,
  cilUser,
  cilLockLocked,
  cilLockUnlocked,
  cibGoogle,
  cilPeople,
  cilUserPlus,
  cilCheck,
  cibTiktok,
  cibGoogleCloud,
  cilFile,
  cilCenterFocus,
  cilCheckAlt,
  cilBan,
  cilArrowTop,
  cilDescription,
  cilChartLine,
  cilBadge,
  cilBullhorn,
  cilRestaurant,
  cilCarAlt,
  cilLevelUp,
  cilCalendarCheck,
  cilInfo,
  cilCalculator,
  cilWallpaper,
  cilMoney,
  cilCopy,
  cilDataTransferDown,
  cilAddressBook,
  cilAperture,
  cilClock,
  cilArrowBottom,
  cilBank,
  cilNewspaper,
  cilSearch,
  cilLinkBroken,
  cilBarChart,
  cilPrint,
  cifSi,
  cifRs,
  cifHr,
  cifUs,
  cifNl,
  cifBg,
  cifBa,
  cifMe,
  cifRo,
  cifMy,
  cifGb,
  cifFr,
  cifDe,
  cifEs,
  cifIt,
  cifAt,
  cifBe,
  cifDk,
  cifFi,
  cifGr
} from '@coreui/icons'

export const iconsSet = Object.assign(
  {
    cibApple,
    cilMoodVeryGood,
    cilApplications,
    cilMoon,
    cilSun,
    cilPlus,
    cilMinus,
    cilTrash,
    cilPen,
    cilX,
    cilBuilding,
    cilLinkAlt,
    cilMenu,
    cilCaretLeft,
    cilCaretRight,
    cilBolt,
    cilSave,
    cilBold,
    cilItalic,
    cilStrikethrough,
    cilParagraph,
    cilHeader,
    cilListNumbered,
    cilList,
    cilActionUndo,
    cilActionRedo,
    cilImage,
    cilInbox,
    cilCalendar,
    cilMediaPlay,
    cilHistory,
    cilLowVision,
    cilCloudUpload,
    cilCloudDownload,
    cilUser,
    cilLockLocked,
    cilLockUnlocked,
    cibGoogle,
    cilPeople,
    cilUserPlus,
    cilCheck,
    cibTiktok,
    cibGoogleCloud,
    cilFile,
    cilCenterFocus,
    cilCheckAlt,
    cilBan,
    cilArrowTop,
    cilDescription,
    cilChartLine,
    cilBadge,
    cilBullhorn,
    cilRestaurant,
    cilCarAlt,
    cilLevelUp,
    cilCalendarCheck,
    cilInfo,
    cilCalculator,
    cilWallpaper,
    cilMoney,
    cilCopy,
    cilDataTransferDown,
    cilAddressBook,
    cilAperture,
    cilClock,
    cilArrowBottom,
    cilBank,
    cilNewspaper,
    cilSearch,
    cilLinkBroken,
    cilBarChart,
    cilPrint,
    cifSi,
    cifRs,
    cifHr,
    cifUs,
    cifNl,
    cifBg,
    cifBa,
    cifMe,
    cifRo,
    cifMy,
    cifGb,
    cifFr,
    cifDe,
    cifEs,
    cifIt,
    cifAt,
    cifBe,
    cifDk,
    cifFi,
    cifGr
  }
)

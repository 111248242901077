<template>
  <CDropdown variant="input-group">
    <CDropdownToggle placement="bottom-end" class="py-1 align-middle" :caret="false" :class="{'border-primary':logo !== false}">
      <img v-if="logo" :src="logo" style="max-height: 2rem;max-width:9rem;" class="me-2 d-none d-sm-inline">
      <div class="avatar avatar-md">
        <img class="avatar-img" :src="$store.getters.isLogged && $store.state.user.photoURL ? $store.state.user.photoURL : avatar" referrerpolicy="no-referrer">
      </div>
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="div" class="fw-semibold py-2 bg-even-lighter">
        {{ $store.getters.isLogged ? $store.state.user.full_name : 'ni prijavljen' }}
      </CDropdownHeader>
      <CDropdownItem @click="userSignOut" class="py-2 clickable"> <CIcon icon="cil-lock-locked" /> {{ $t('logout') }} </CDropdownItem>
      <CDropdownHeader component="div" class="fw-semibold py-2 bg-even-lighter">
        {{ $t('lang') }}
      </CDropdownHeader>
      <CDropdownItem v-for="lang in langs" :key="lang.code" @click="changeLocale(lang.code)" class="clickable" :class="{ 'bg-lighter': (lang.code == $i18n.locale)}">{{ lang.name }}</CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatar.jpeg'
import auth from '@/auth';

export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
      langs: [
        { name: 'English', code: 'en' },
        { name: 'slovenščina', code: 'sl' },
        // { name: 'hrvatski', code: 'hr' },
        // { name: 'српски', code: 'sr' },
        // { name: 'Deutsch', code: 'de' },
        // { name: 'italiano', code: 'it' },
        // { name: 'español', code: 'es' },
        // { name: 'македонски', code: 'mk' },
        // { name: 'shqip', code: 'sq' },

      ]
    }
  },
  methods: {
    userSignOut() {
      auth.logout().then(() => {
        this.$store.commit('logoutUser');
        this.$router.push('/login');
      })
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.$store.commit('updateLanguage', locale);
    }
  },
  computed: {
    logo() {
      if (this.$store.state.user && this.$store.state.user.agency_logo) {
        return require(`@/assets/brand/${this.$store.state.user.agency_logo}`)
      } else {
        return false;
      }
    }
  }
}
</script>
<style>
.bg-lighter {
  background-color: rgba(255,255,255,0.05) !important;
}
.bg-even-lighter {
  background-color: rgba(255,255,255,0.1) !important;
}
</style>

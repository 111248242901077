<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
      <img :src="logo" height="44" class="my-3">
    </CSidebarBrand>
    <AppSidebarNavs />  
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import AppSidebarNavs from './AppSidebarNavs'
import logo from '@/assets/brand/916hub-logo.svg'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNavs,
  },
  setup() {
    const store = useStore()
    return {
      logo,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>
<style>
.sidebar {
  background-color: rgb(28, 28, 28);
}
.sidebar-brand {
  background-color: rgb(21, 21, 21);
}
.nav-link, .nav-icon {
  color: rgba(255, 255, 255, 0.9) !important;
}
</style>

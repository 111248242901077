<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <img :src="logo" height="48" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav class="ms-auto me-4 d-none d-lg-flex">
        <!-- <CButtonGroup aria-label="Theme switch">
          <CFormCheck
            id="btn-light-theme"
            type="radio"
            :button="{ color: 'primary' }"
            name="theme-switch"
            autocomplete="off"
            :checked="$store.state.theme === 'default'"
            @change="
              (event) =>
                $store.commit({
                  type: 'toggleTheme',
                  value: 'default',
                })
            "
          >
            <template #label><CIcon icon="cil-sun" /></template>
          </CFormCheck>
          <CFormCheck
            id="btn-dark-theme"
            type="radio"
            :button="{ color: 'primary' }"
            name="theme-switch"
            autocomplete="off"
            :checked="$store.state.theme === 'dark'"
            @change="
              (event) =>
                $store.commit({
                  type: 'toggleTheme',
                  value: 'dark',
                })
            "
          >
            <template #label><CIcon icon="cil-moon" /></template>
          </CFormCheck>
        </CButtonGroup> -->
      </CHeaderNav>
      <CHeaderNav class="ms-3">
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import logo from '@/assets/brand/916hub-logo.svg'

export default {
  name: 'AppHeader',
  components: {
    AppHeaderDropdownAccnt,
  },
  setup() {
    return {
      logo,
    }
  },
}
</script>
<style>
.header {
  background-color: rgb(21, 21, 21);
}
.header-toggler {
  color: rgba(255, 255, 255, 0.87);
}
.header-toggler:hover {
  color: rgba(255, 255, 255, 0.7);
}
</style>

<template>
  <CSidebarNav>
    <div v-for="group in userNavs" :key="group.group_id">
      <CNavTitle v-if="group.group_name">{{ $t(`pageGroups.${group.group_name}`) }}</CNavTitle>
      <CNavItem v-for="page in group.pages" :key="page.page_id">
        <router-link :to="page.route" :class="'nav-link' + (page.active ? ' active' : '')">
          <CIcon customClassName="nav-icon" :icon="page.icon"/>
          {{ $t(`pages.${page.page_name}`) }}
        </router-link>
      </CNavItem>
    </div>
  </CSidebarNav>
</template>
<script>
export default {
  name: 'AppSidebarNavs',
  methods: {
    isActiveLink(link) {
      if (link === undefined) {
        return false
      }

      let route = this.$route;
      if (route.hash === link) {
        return true
      }

      const currentPath = this.normalizePath(route.path)
      const targetPath = this.normalizePath(link)

      return currentPath === targetPath
    },
    normalizePath (path) {
      return decodeURI(path)
        .replace(/#.*$/, '')
        .replace(/(index)?\.(html)$/, '')
    }
  },
  computed: {
    userNavs() {
      if (this.$store.getters.isLogged) {
        let navs = [...this.$store.state.user.navs];
        for (let i = 0; i < navs.length; i++) {
          const group = navs[i];
          for (let j = 0; j < group.pages.length; j++) {
            const page = group.pages[j];
            page.active = this.isActiveLink(page.route);
          }
        }
        return navs;
      }
      else return [];
    }
  }
  // components: { RouterLink }
}
</script>
import { createI18n } from "vue-i18n";
import sl from "./locales/sl.json";
import en from "./locales/en.json";
import de from "./locales/de.json";
import es from "./locales/es.json";
import hr from "./locales/hr.json";
import it from "./locales/it.json";
import mk from "./locales/mk.json";
import sq from "./locales/sq.json";
import sr from "./locales/sr.json";
import store from '@/store';

export default createI18n({
  locale: store.state.language,
  fallbackLocale: "en",
  messages: {
    sl,
    en,
    de,
    es,
    hr,
    it,
    mk,
    sq,
    sr
  },
  legacy: false
});